import * as React from "react"

import ProportionGreen from "../components/biggerPicture/ProportionGreen"
import Layout from "../components/root/Layout"
import LoadingSpinner from "../components/root/LoadingSpinner"
import StatCard from "../components/reports/StatCard"
import PopularSitesOverview from "../components/biggerPicture/PopularSitesOverview"

const Stats = () => {
  const [state, setState] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    fetch(
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:3000/bigger-picture"
        : "https://v2api.digitalfootprint.earth/bigger-picture"
    )
      .then(res => res.json())
      .then(data => {
        setState(data)
        setLoading(false)
      })
  }, [])

  React.useEffect(() => {
    console.log(state)
  }, [state])

  if (loading) {
    return (
      <Layout>
        <LoadingSpinner text="Gathering Data..." />
      </Layout>
    )
  }

  return (
    <Layout title="Stats">
      <div className="text-center flex flex-col space-y-4 my-16 bg-primary ">
        <h2 className="text-6xl font-extrabold text-primary">
          The Bigger Picture
        </h2>
        <div className="col-span-12 w-full">
          <h3 className="font-bold text-xl text-primary mb-6">
            Average Site Performance
          </h3>
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 lg:col-span-1">
              <StatCard
                stat={`${state.averageHosting.toFixed(2)}/3`}
                description="Hosting Score"
              />
            </div>
            <div className="col-span-3 lg:col-span-1">
              <StatCard
                stat={`${state.averagePageWeight.toFixed(2)}/3`}
                description="Page Weight Score"
              />
            </div>
            <div className="col-span-3 lg:col-span-1">
              <StatCard
                stat={`${state.averagePerformance.toFixed(2)}/3`}
                description="Performance Score"
              />
            </div>
          </div>
        </div>

        <ProportionGreen
          greenUrls={state.greenUrls}
          notGreenUrls={state.notGreenUrls}
        />
        <PopularSitesOverview />
      </div>
    </Layout>
  )
}

export default Stats
